import {
  Add,
  ArrowForwardIosRounded,
  Close,
  PendingActionsOutlined,
  TrendingDown,
  TrendingUp,
  WarningAmberRounded,
} from "@mui/icons-material";
import {
  alpha,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  useTheme,
} from "@mui/material";
import { ReactComponent as LeafIcon } from "assets/images/co2-leaf.svg";
import { ReactComponent as FuelIcon } from "assets/images/fuel.svg";
import statsBarBgLayer1 from "assets/images/stats-bar-bg-layer-1.png";
import statsBarBgLayer2 from "assets/images/stats-bar-bg-layer-2.png";
import moment from "moment";
import { Fragment, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import { authorizedFetch, formatCompactNumber } from "utils";
import { CMS_STATS_URL } from "utils/constants";

const Highlights = ({ params, dateRangeInWords }: any) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const [activeTab, setActiveTab] = useState(0);
  const [compareWith, setCompareWith] = useState(["", "", ""]); // separate state for each tab. "bookings" | "consumption" | "revenue"

  const {
    startTime,
    endTime,
    vehicleType,
    chargerType,
    accessType,
    city,
    masterView,
  } = params;

  const granularity =
    moment(endTime).diff(moment(startTime), "years") >= 1
      ? "month"
      : moment(endTime).diff(moment(startTime), "months") > 1
        ? "week"
        : "day";

  const metric =
    activeTab === 0
      ? "booking_count"
      : activeTab === 1
        ? "consumption"
        : "revenue";

  const isWeek = dateRangeInWords === "Last 7 days";
  const isMonth = dateRangeInWords === "Last 30 days";

  // const showPreviousPeriod = (isWeek || isMonth) && !compareWith[activeTab];
  const showPreviousPeriod = !compareWith[activeTab];

  const compareWithIndex =
    compareWith[activeTab] === "bookings"
      ? 0
      : compareWith[activeTab] === "consumption"
        ? 1
        : 2;

  // const rangeInWords = isWeek
  //   ? "this week"
  //   : isMonth
  //     ? "this month"
  //     : "this period";
  const rangeInWords = "this period";

  const colors = ["#6F7BF7", "#FFA257", "#CC52E2", "#808080"]; // bookings, consumption, revenue, previous period
  const currentColor = colors[activeTab];
  const compareWithColor = colors[compareWithIndex];

  const { isLoading, data } = useQuery(
    [
      "GET cms-stats /metrics/bookings",
      startTime,
      endTime,
      vehicleType,
      chargerType,
      accessType,
      city,
      masterView,
    ],
    () =>
      authorizedFetch(
        `${CMS_STATS_URL}/metrics/bookings?start_time=${startTime}&end_time=${endTime}${vehicleType}${chargerType}${accessType}${city}&master=${masterView}`,
      ),
  );

  const { data: trendsData } = useQuery(
    [
      "GET cms-stats /tabular/trends",
      metric,
      startTime,
      endTime,
      vehicleType,
      chargerType,
      accessType,
      city,
      granularity,
      masterView,
    ],
    () =>
      authorizedFetch(
        `${CMS_STATS_URL}/tabular/trends?metric=${metric}&start_time=${startTime}&end_time=${endTime}${vehicleType}${chargerType}${accessType}${city}&granularity=${granularity}&master=${masterView}`,
      ),
    { staleTime: Infinity },
  );

  const compareWithMetric =
    compareWithIndex === 0
      ? "booking_count"
      : compareWithIndex === 1
        ? "consumption"
        : "revenue";
  const { data: compareWithTrendsData } = useQuery(
    [
      "GET cms-stats /tabular/trends",
      compareWithMetric,
      startTime,
      endTime,
      vehicleType,
      chargerType,
      accessType,
      city,
      granularity,
      masterView,
    ],
    () =>
      authorizedFetch(
        `${CMS_STATS_URL}/tabular/trends?metric=${compareWithMetric}&start_time=${startTime}&end_time=${endTime}${vehicleType}${chargerType}${accessType}${city}&granularity=${granularity}&master=${masterView}`,
      ),
    { staleTime: Infinity, enabled: !!compareWith[activeTab] },
  );

  const startTimePrevious = moment(startTime)
    .subtract({ days: moment(endTime).diff(moment(startTime), "days") + 1 })
    .toISOString();

  const endTimePrevious = moment(endTime)
    .subtract({ days: moment(endTime).diff(moment(startTime), "days") + 1 })
    .toISOString();

  const { data: previousTrendsData } = useQuery(
    [
      "GET cms-stats /tabular/trends",
      metric,
      startTimePrevious,
      endTimePrevious,
      vehicleType,
      chargerType,
      accessType,
      city,
      granularity,
      masterView,
    ],
    () =>
      authorizedFetch(
        `${CMS_STATS_URL}/tabular/trends?metric=${metric}&start_time=${startTimePrevious}&end_time=${endTimePrevious}${vehicleType}${chargerType}${accessType}${city}&granularity=${granularity}&master=${masterView}`,
      ),
    { staleTime: Infinity, enabled: showPreviousPeriod },
  );

  const chartData = useMemo(() => {
    let chartData: any = [];
    if (!trendsData?.data) return chartData;
    Object.entries(trendsData.data.plot || {}).forEach(([key, value]: any) => {
      chartData.push({
        x: (granularity === "month"
          ? moment(key).format("MMM 'YY")
          : moment(key).format("DD MMM")
        ).split(" "),
        y: roundTo2Decimals(value),
        // y: value,
      });
    });
    return chartData;
  }, [trendsData, granularity]);

  function roundTo2Decimals(num: number) {
    return Math.round(num * 100) / 100;
  }

  const compareWithChartData = useMemo(() => {
    let compareWithChartData: any = [];
    if (!compareWithTrendsData?.data) return compareWithChartData;
    Object.entries(compareWithTrendsData.data.plot || {}).forEach(
      ([key, value]: any) => {
        compareWithChartData.push({
          x: (granularity === "month"
            ? moment(key).format("MMM 'YY")
            : moment(key).format("DD MMM")
          ).split(" "),
          y: roundTo2Decimals(value),
          // y: value,
        });
      },
    );
    // if shorter than main chart, pad with 0s at the beginning
    if (compareWithChartData.length < chartData.length) {
      const padding = Array(chartData.length - compareWithChartData.length)
        .fill(0)
        .map((_, i) => ({
          x: compareWithChartData[0].x,
          y: 0,
        }));
      compareWithChartData = [...padding, ...compareWithChartData];
    }
    return compareWithChartData;
  }, [compareWithTrendsData, granularity, chartData.length]);

  const previousChartData = useMemo(() => {
    let previousChartData: any = [];
    if (!previousTrendsData?.data) return previousChartData;
    Object.entries(previousTrendsData.data.plot || {}).forEach(
      ([key, value]: any) => {
        previousChartData.push({
          x: (granularity === "month"
            ? moment(key).format("MMM 'YY")
            : moment(key).format("DD MMM")
          ).split(" "),
          y: roundTo2Decimals(value),
          // y: value,
        });
      },
    );
    // if shorter than main chart, pad with 0s at the beginning
    if (previousChartData.length < chartData.length) {
      const padding = Array(chartData.length - previousChartData.length)
        .fill(0)
        .map((_, i) => ({
          x: previousChartData[0].x,
          y: 0,
        }));
      previousChartData = [...padding, ...previousChartData];
    }
    return previousChartData;
  }, [previousTrendsData, granularity, chartData.length]);

  const stats = useMemo(
    () => ({
      bookings: [
        {
          label: `Bookings ${rangeInWords}`,
          value: !isLoading
            ? formatCompactNumber(data?.data?.total_bookings?.value)
            : "-",
          percentage: data?.data?.total_bookings?.growth || null,
        },
        {
          label: "Avg. bookings per charger",
          value: !isLoading
            ? formatCompactNumber(
                data?.data?.average_bookings_per_charger?.value,
              )
            : "-",
          percentage: data?.data?.average_bookings_per_charger?.growth || null,
        },
        {
          label: "Avg. bookings per user",
          value: !isLoading
            ? formatCompactNumber(data?.data?.average_bookings_per_user?.value)
            : "-",
          percentage: data?.data?.average_bookings_per_user?.growth || null,
        },
      ],
      consumption: [
        {
          label: `Consumption ${rangeInWords}`,

          value: !isLoading ? (
            <>
              {formatCompactNumber(
                data?.data?.energy_consumption?.value >= 1000000
                  ? data?.data?.energy_consumption?.value / 1000000
                  : data?.data?.energy_consumption?.value >= 1000
                    ? data?.data?.energy_consumption?.value / 1000
                    : data?.data?.energy_consumption?.value,
                {
                  maximumFractionDigits:
                    data?.data?.energy_consumption?.value < 10
                      ? 2
                      : data?.data?.energy_consumption?.value < 100 ||
                          data?.data?.energy_consumption?.value >= 1000
                        ? 1
                        : 0,
                },
              )}
              <Box component="span" fontSize="0.75em">
                {" "}
                {data?.data?.energy_consumption?.value >= 1000000
                  ? "GWh"
                  : data?.data?.energy_consumption?.value >= 1000
                    ? "MWh"
                    : "kWh"}
              </Box>
            </>
          ) : (
            "-"
          ),
          percentage: data?.data?.energy_consumption?.growth || null,
        },
        {
          label: "Avg. consumption per charger",
          value: !isLoading ? (
            <>
              {formatCompactNumber(
                data?.data?.average_consumption_per_charger?.value >= 1000000
                  ? data?.data?.average_consumption_per_charger?.value / 1000000
                  : data?.data?.average_consumption_per_charger?.value >= 1000
                    ? data?.data?.average_consumption_per_charger?.value / 1000
                    : data?.data?.average_consumption_per_charger?.value,
                {
                  maximumFractionDigits:
                    data?.data?.average_consumption_per_charger?.value < 10
                      ? 2
                      : data?.data?.average_consumption_per_charger?.value <
                            100 ||
                          data?.data?.average_consumption_per_charger?.value >=
                            1000
                        ? 1
                        : 0,
                },
              )}
              <Box component="span" fontSize="0.75em">
                {" "}
                {data?.data?.average_consumption_per_charger?.value >= 1000000
                  ? "GWh"
                  : data?.data?.average_consumption_per_charger?.value >= 1000
                    ? "MWh"
                    : "kWh"}
              </Box>
            </>
          ) : (
            "-"
          ),
          percentage:
            data?.data?.average_consumption_per_charger?.growth || null,
        },
        {
          label: "Avg. consumption per user",
          value: !isLoading ? (
            <>
              {formatCompactNumber(
                data?.data?.average_consumption_per_user?.value >= 1000000
                  ? data?.data?.average_consumption_per_user?.value / 1000000
                  : data?.data?.average_consumption_per_user?.value >= 1000
                    ? data?.data?.average_consumption_per_user?.value / 1000
                    : data?.data?.average_consumption_per_user?.value,
                {
                  maximumFractionDigits:
                    data?.data?.average_consumption_per_user?.value < 10
                      ? 2
                      : data?.data?.average_consumption_per_user?.value < 100 ||
                          data?.data?.average_consumption_per_user?.value >=
                            1000
                        ? 1
                        : 0,
                },
              )}
              <Box component="span" fontSize="0.75em">
                {" "}
                {data?.data?.average_consumption_per_user?.value >= 1000000
                  ? "GWh"
                  : data?.data?.average_consumption_per_user?.value >= 1000
                    ? "MWh"
                    : "kWh"}
              </Box>
            </>
          ) : (
            "-"
          ),
          percentage: data?.data?.average_consumption_per_user?.growth || null,
        },
      ],
      revenue: [
        {
          label: `Revenue ${rangeInWords}`,
          value: !isLoading
            ? "₹" +
              (data?.data?.revenue?.value < 100000
                ? Math.floor(data?.data?.revenue?.value).toLocaleString()
                : formatCompactNumber(data?.data?.revenue?.value, {
                    locale: "en-IN",
                    maximumFractionDigits: 2,
                  }))
            : "-",
          percentage: data?.data?.revenue?.growth || null,
        },
        {
          label: "Avg. revenue per charger",
          value: !isLoading
            ? "₹" +
              (data?.data?.average_revenue_per_charger?.value < 100000
                ? Math.floor(
                    data?.data?.average_revenue_per_charger?.value,
                  ).toLocaleString()
                : formatCompactNumber(
                    data?.data?.average_revenue_per_charger?.value,
                    {
                      locale: "en-IN",
                      maximumFractionDigits: 2,
                    },
                  ))
            : "-",
          percentage: data?.data?.average_revenue_per_charger?.growth || null,
        },
        {
          label: "Pending Payments",
          value: !isLoading ? (
            <>
              <Box display="flex" alignItems="center">
                ₹
                {data?.data?.pending_payments < 100000
                  ? Math.floor(data?.data?.pending_payments).toLocaleString()
                  : formatCompactNumber(data?.data?.pending_payments, {
                      locale: "en-IN",
                      maximumFractionDigits: 2,
                    })}
                <WarningAmberRounded
                  fontSize="inherit"
                  sx={{ ml: 1, color: "#FFAB01" }}
                />
              </Box>
            </>
          ) : (
            "-"
          ),
        },
      ],
    }),
    [isLoading, data, rangeInWords],
  );

  return (
    <Box pt={{ xs: 2, md: 3.5 }}>
      <Box border={0} m={-3} p={3} sx={{ overflowX: "auto" }}>
        <Box minWidth={{ xs: 1100, lg: 1200 }}>
          {/* Stats bar (CO₂ Reduced and Fuel Avoided) */}
          <Box
            borderRadius="16px"
            bgcolor={(theme) =>
              isDarkMode ? "#202020" : theme.palette.background.paper
            }
            boxShadow={(theme) => theme.customShadows.card}
            p="6px"
            // height="90px"
            mb={3.5}
          >
            <Box
              position="relative"
              borderRadius="10px"
              overflow="hidden"
              bgcolor={(theme) => theme.customColors.background.lightGrey}
            >
              <img
                src={statsBarBgLayer2}
                alt=""
                style={{
                  position: "absolute",
                  top: 0,
                  right: -6,
                  width: "auto",
                  height: "100%",
                  objectFit: "contain",
                  pointerEvents: "none",
                  userSelect: "none",
                }}
              />
              <Box
                component="img"
                src={statsBarBgLayer1}
                alt=""
                sx={{
                  display: { xs: "none", lg: "block" },
                  position: "absolute",
                  top: -6,
                  right: -6,
                  width: "auto",
                  height: 70,
                  pointerEvents: "none",
                  userSelect: "none",
                }}
              />
              <Box
                sx={{
                  background: (theme) =>
                    !isDarkMode
                      ? `linear-gradient(to right, #F7F7F7, ${alpha("#F7F7F7", 0.5)}, ${alpha("#F7F7F7", 0.2)})`
                      : `linear-gradient(to right, ${theme.palette.background.paper} 50%, ${alpha(theme.palette.background.paper, 0.2)})`,
                  position: "absolute",
                  inset: 0,
                  pointerEvents: "none",
                  userSelect: "none",
                }}
              />
              <Box
                position="relative"
                display="flex"
                alignItems="center"
                height={70 - 12}
              >
                <Box
                  ml={2.5}
                  position="relative"
                  width={50}
                  alignSelf="stretch"
                >
                  <LeafIcon
                    style={{ position: "absolute", top: -6, left: 0 }}
                    width={50}
                  />
                </Box>
                <Box ml={3} fontSize="18px" lineHeight="24px">
                  CO₂
                  <br />
                  Reduced
                </Box>
                <Box ml={3.5} fontSize={{ xs: 24, lg: 28 }} fontWeight={800}>
                  {isLoading ? (
                    <Skeleton width={150} />
                  ) : (
                    <>
                      {formatCompactNumber(data?.data?.emissions_saved?.value)}
                      <Box component="span" fontSize={{ xs: 18, lg: 24 }}>
                        {" "}
                        kg
                      </Box>
                    </>
                  )}
                </Box>
                {typeof data?.data?.emissions_saved?.growth === "number" && (
                  <Box
                    sx={{
                      ml: 1.5,
                      fontSize: { xs: 14, lg: 18 },
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ mr: 0.5 }}>
                      {data.data.emissions_saved.growth > 0 ? "+" : ""}
                      {data.data.emissions_saved.growth.toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits:
                            data.data.emissions_saved.growth < 100 ? 1 : 0,
                        },
                      )}
                      %
                    </Box>
                    {data.data.emissions_saved.growth > 0 && (
                      <TrendingUp fontSize="inherit" color="success" />
                    )}
                    {data.data.emissions_saved.growth < 0 && (
                      <TrendingDown fontSize="inherit" color="error" />
                    )}
                  </Box>
                )}
                <Box
                  alignSelf="stretch"
                  borderLeft={(theme) =>
                    `2px dashed ${theme.customColors.background.grey}`
                  }
                  mx={6}
                />
                <Box position="relative" width={61} alignSelf="stretch">
                  <FuelIcon
                    style={{ position: "absolute", top: -6, left: 0 }}
                    width={61}
                  />
                </Box>
                <Box ml={3} fontSize="18px" lineHeight="24px">
                  Fuel
                  <br />
                  Avoided
                </Box>
                <Box ml={3.5} fontSize={{ xs: 24, lg: 28 }} fontWeight={800}>
                  {isLoading ? (
                    <Skeleton width={100} />
                  ) : (
                    <>
                      {formatCompactNumber(
                        data?.data?.petrol_litres_saved?.value,
                      )}
                      <Box component="span" fontSize={{ xs: 18, lg: 24 }}>
                        {" "}
                        Lit.
                      </Box>
                    </>
                  )}
                </Box>
                {typeof data?.data?.petrol_litres_saved?.growth ===
                  "number" && (
                  <Box
                    sx={{
                      ml: 1.5,
                      fontSize: { xs: 14, lg: 18 },
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ mr: 0.5 }}>
                      {data.data.petrol_litres_saved.growth > 0 ? "+" : ""}
                      {data.data.petrol_litres_saved.growth.toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits:
                            data.data.petrol_litres_saved.growth < 100 ? 1 : 0,
                        },
                      )}
                      %
                    </Box>
                    {data.data.petrol_litres_saved.growth > 0 && (
                      <TrendingUp fontSize="inherit" color="success" />
                    )}
                    {data.data.petrol_litres_saved.growth < 0 && (
                      <TrendingDown fontSize="inherit" color="error" />
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          {/* Bookings, Energy Consumption, Revenue */}
          <Box>
            {/* Tabs */}
            <Box display="flex">
              {[
                {
                  label: "Total Bookings",
                  value: !isLoading
                    ? formatCompactNumber(data?.data?.total_bookings?.value)
                    : "-",
                  percentage: data?.data?.total_bookings?.growth || null,
                },
                {
                  label: (
                    <>
                      Total Energy
                      <br />
                      Consumption
                    </>
                  ),
                  value: !isLoading ? (
                    <>
                      {formatCompactNumber(
                        data?.data?.energy_consumption?.value >= 1000000
                          ? data?.data?.energy_consumption?.value / 1000000
                          : data?.data?.energy_consumption?.value >= 1000
                            ? data?.data?.energy_consumption?.value / 1000
                            : data?.data?.energy_consumption?.value,
                        {
                          maximumFractionDigits:
                            data?.data?.energy_consumption?.value < 10
                              ? 2
                              : data?.data?.energy_consumption?.value < 100 ||
                                  data?.data?.energy_consumption?.value >= 1000
                                ? 1
                                : 0,
                        },
                      )}
                      <Box component="span" fontSize="0.75em">
                        {" "}
                        {data?.data?.energy_consumption?.value >= 1000000
                          ? "GWh"
                          : data?.data?.energy_consumption?.value >= 1000
                            ? "MWh"
                            : "kWh"}
                      </Box>
                    </>
                  ) : (
                    "-"
                  ),
                  percentage: data?.data?.energy_consumption?.growth || null,
                },
                {
                  label: "Total Revenue",
                  value: !isLoading
                    ? "₹" +
                      (data?.data?.revenue?.value < 100000
                        ? Math.floor(
                            data?.data?.revenue?.value,
                          ).toLocaleString()
                        : formatCompactNumber(data?.data?.revenue?.value, {
                            locale: "en-IN",
                            maximumFractionDigits: 2,
                          }))
                    : "-",
                  percentage: data?.data?.revenue?.growth || null,
                },
              ].map((el, i) => (
                <Box
                  key={i}
                  role="button"
                  onClick={() => setActiveTab(i)}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  bgcolor={(theme) =>
                    activeTab === i
                      ? theme.palette.background.paper
                      : isDarkMode
                        ? "#1f1f1f"
                        : theme.customColors.background.lightGrey
                  }
                  width={{ xs: 350, lg: 396 }}
                  height={81}
                  py={2}
                  px={3.5}
                  boxShadow={(theme) =>
                    isDarkMode
                      ? `0px 0px 14px rgba(0, 0, 0, 0.4)`
                      : theme.customShadows.medium
                  }
                  position={activeTab === i ? "relative" : "unset"}
                  zIndex={
                    // yeah... this is a bit hacky
                    activeTab === i
                      ? 2
                      : activeTab === 2 && i === 0
                        ? 0
                        : i === 0
                          ? 2
                          : i === 1
                            ? 1
                            : 0
                  }
                  borderRadius="14px 14px 0 0"
                  borderBottom={3}
                  borderColor={(theme) =>
                    activeTab === i ? theme.palette.primary.main : "transparent"
                  }
                  sx={{
                    cursor: "pointer",
                    transition: "all 150ms",
                    transform: i !== 0 ? "translateX(-4px)" : undefined,
                  }}
                >
                  <Box
                    fontSize={{ xs: 16, lg: 18 }}
                    lineHeight="1.35em"
                    fontWeight={activeTab === i ? 700 : 500}
                  >
                    {el.label}
                  </Box>
                  <Box
                    ml={1}
                    display="flex"
                    flexDirection="column"
                    alignItems="end"
                  >
                    <Box
                      fontSize={{ xs: 24, lg: 28 }}
                      lineHeight="1.15em"
                      fontWeight={activeTab === i ? 800 : 700}
                      color={activeTab === i ? "primary.main" : "text.primary"}
                      whiteSpace="nowrap"
                    >
                      {el.value}
                    </Box>
                    {typeof el.percentage === "number" && (
                      <Box
                        sx={{
                          fontSize: { xs: 12, lg: 14 },
                          fontWeight: 700,
                          display: "flex",
                          alignItems: "center",
                          color: (theme) =>
                            activeTab === i
                              ? theme.customColors.text.plain
                              : theme.palette.text.primary,
                        }}
                      >
                        <Box sx={{ mr: 0.25 }}>
                          {el.percentage > 0 ? "+" : ""}
                          {el.percentage.toLocaleString(undefined, {
                            maximumFractionDigits: el.percentage < 100 ? 1 : 0,
                          })}
                          %
                        </Box>
                        {el.percentage > 0 && (
                          <TrendingUp fontSize="inherit" color="success" />
                        )}
                        {el.percentage < 0 && (
                          <TrendingDown fontSize="inherit" color="error" />
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
            {/* Trend comparison view */}
            <Box
              bgcolor={(theme) => theme.palette.background.paper}
              p={3.5}
              boxShadow={(theme) => theme.customShadows.card}
              position="relative"
              borderRadius="0 14px 14px 14px"
              zIndex={2}
            >
              {/* Title and stats */}
              <Box
                display="flex"
                alignItems="start"
                justifyContent="space-between"
              >
                {/* Title and chips */}
                <Box>
                  <Box
                    fontSize={{ xs: 16, lg: 18 }}
                    fontWeight={700}
                    color={(theme) => theme.customColors.text.plain2}
                  >
                    {compareWith[activeTab]
                      ? "Trend Comparison"
                      : `${activeTab === 0 ? "Bookings" : activeTab === 1 ? "Energy Consumption" : "Revenue"}  Trend`}
                  </Box>
                  <Box mt={2.5} display="flex" alignItems="center">
                    <Box
                      height={32}
                      px={2}
                      display="flex"
                      alignItems="center"
                      fontSize={14}
                      lineHeight="1em"
                      border={`1px solid ${currentColor}`}
                      color={(theme) => theme.customColors.text.plain2}
                      bgcolor={alpha(currentColor, 0.2)}
                      borderRadius={50}
                    >
                      {/* {activeTab !== 1 && "Total "} */}
                      {activeTab === 0
                        ? "Bookings"
                        : activeTab === 1
                          ? "Energy Consumption"
                          : "Revenue"}
                    </Box>
                    <Box
                      alignSelf="stretch"
                      borderLeft={(theme) =>
                        `1px dashed ${theme.customColors.lightGrey}`
                      }
                      mx={{ xs: 1, lg: 2.5 }}
                    />
                    {compareWith[activeTab] ? (
                      <Box
                        height={32}
                        px={2}
                        display="flex"
                        alignItems="center"
                        fontSize={14}
                        lineHeight="1em"
                        border={`1px solid ${compareWithColor}`}
                        color={(theme) => theme.customColors.text.plain2}
                        bgcolor={alpha(compareWithColor, 0.2)}
                        borderRadius={50}
                      >
                        {/* {compareWith[activeTab] !== "consumption" && "Total "} */}
                        {compareWith[activeTab] === "bookings"
                          ? "Bookings"
                          : compareWith[activeTab] === "consumption"
                            ? "Energy Consumption"
                            : "Revenue"}
                        <IconButton
                          size="small"
                          color="inherit"
                          sx={{ ml: 0.5, mr: -1.75 }}
                          onClick={() =>
                            setCompareWith((prev) => {
                              const newCompareWith = [...prev];
                              newCompareWith[activeTab] = "";
                              return newCompareWith;
                            })
                          }
                        >
                          <Close sx={{ fontSize: 16 }} />
                        </IconButton>
                      </Box>
                    ) : (
                      <AddComparisonButton {...{ activeTab, setCompareWith }} />
                    )}
                  </Box>
                </Box>

                {/* Stats; Close button for trend comparison view */}
                {!compareWith[activeTab] ? (
                  <Box display="flex" alignItems="start">
                    {(activeTab === 0
                      ? [
                          {
                            label: (
                              <>
                                Bookings
                                <br />
                                {rangeInWords}
                              </>
                            ),
                            value: stats.bookings[0].value,
                            percentage: stats.bookings[0].percentage,
                          },
                          {
                            label: (
                              <>
                                Avg. bookings
                                <br />
                                per charger
                              </>
                            ),
                            value: stats.bookings[1].value,
                            percentage: stats.bookings[1].percentage,
                          },
                          {
                            label: (
                              <>
                                Avg. bookings
                                <br />
                                per user
                              </>
                            ),
                            value: stats.bookings[2].value,
                            percentage: stats.bookings[2].percentage,
                          },
                        ]
                      : activeTab === 1
                        ? [
                            {
                              label: (
                                <>
                                  Consumption
                                  <br />
                                  {rangeInWords}
                                </>
                              ),
                              value: stats.consumption[0].value,
                              percentage: stats.consumption[0].percentage,
                            },
                            {
                              label: (
                                <>
                                  Avg. consumption
                                  <br />
                                  per charger
                                </>
                              ),
                              value: stats.consumption[1].value,
                              percentage: stats.consumption[1].percentage,
                            },
                            {
                              label: (
                                <>
                                  Avg. consumption
                                  <br />
                                  per user
                                </>
                              ),
                              value: stats.consumption[2].value,
                              percentage: stats.consumption[2].percentage,
                            },
                          ]
                        : [
                            {
                              label: (
                                <>
                                  Revenue
                                  <br />
                                  {rangeInWords}
                                </>
                              ),
                              value: stats.revenue[0].value,
                              percentage: stats.revenue[0].percentage,
                            },
                            {
                              label: (
                                <>
                                  Avg. revenue
                                  <br />
                                  per charger
                                </>
                              ),
                              value: stats.revenue[1].value,
                              percentage: stats.revenue[1].percentage,
                            },
                            {
                              label: (
                                <>
                                  Pending
                                  <br />
                                  Payments
                                </>
                              ),
                              value: stats.revenue[2].value,
                            },
                          ]
                    ).map((el, i, arr) => (
                      <Fragment key={i}>
                        <Box
                          key={i}
                          ml={3}
                          color={(theme) => theme.customColors.text.plain2}
                          minWidth={{ xs: 120, lg: 140 }}
                        >
                          <Box fontSize={14} lineHeight="1.125em">
                            {el.label}
                          </Box>
                          <Box mt={0.75} display="flex" alignItems="center">
                            <Box
                              fontSize={{ xs: 20, lg: 24 }}
                              lineHeight="1em"
                              fontWeight={700}
                            >
                              {el.value}
                            </Box>
                            {typeof el.percentage === "number" && (
                              <Box
                                sx={{
                                  ml: 1,
                                  fontSize: { xs: 12, lg: 14 },
                                  fontWeight: 600,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box sx={{ mr: 0.25 }}>
                                  {el.percentage > 0 ? "+" : ""}
                                  {el.percentage.toLocaleString(undefined, {
                                    maximumFractionDigits:
                                      el.percentage < 100 ? 1 : 0,
                                  })}
                                  %
                                </Box>
                                {el.percentage > 0 && (
                                  <TrendingUp
                                    fontSize="inherit"
                                    color="success"
                                  />
                                )}
                                {el.percentage < 0 && (
                                  <TrendingDown
                                    fontSize="inherit"
                                    color="error"
                                  />
                                )}
                              </Box>
                            )}
                          </Box>
                        </Box>
                        {i !== arr.length - 1 && (
                          <Box
                            ml={3}
                            alignSelf="stretch"
                            borderLeft={(theme) =>
                              `1px solid ${theme.customColors.lightGrey}`
                            }
                          />
                        )}
                      </Fragment>
                    ))}
                  </Box>
                ) : (
                  <Box display="flex">
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(3, 1fr)"
                      columnGap={{ xs: 2, lg: 4 }}
                      rowGap={1}
                    >
                      {["main-stats", "compare-with-stats"].map((el, i) => {
                        const tabIndex = i === 0 ? activeTab : compareWithIndex;
                        const statsKey =
                          tabIndex === 0
                            ? "bookings"
                            : tabIndex === 1
                              ? "consumption"
                              : "revenue";
                        return (
                          <Fragment key={i}>
                            {stats[statsKey].map((stat, j) => (
                              <Box key={j} display="flex" alignItems="center">
                                <Box
                                  width="3px"
                                  height="26px"
                                  bgcolor={colors[tabIndex]}
                                  borderRadius={10}
                                />
                                <Box ml={1.5}>
                                  <Box fontSize={14} lineHeight="1.5em">
                                    {stat.label}
                                  </Box>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    lineHeight="1.25em"
                                  >
                                    <Box fontSize={16} fontWeight={700}>
                                      {stat.value}
                                    </Box>
                                    {typeof stat.percentage === "number" && (
                                      <Box
                                        sx={{
                                          ml: 0.5,
                                          fontSize: { xs: 12 },
                                          fontWeight: 600,
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Box sx={{ mr: 0.25 }}>
                                          {stat.percentage > 0 ? "+" : ""}
                                          {stat.percentage.toLocaleString(
                                            undefined,
                                            {
                                              maximumFractionDigits:
                                                stat.percentage < 100 ? 1 : 0,
                                            },
                                          )}
                                          %
                                        </Box>
                                        {stat.percentage > 0 && (
                                          <TrendingUp
                                            fontSize="inherit"
                                            color="success"
                                          />
                                        )}
                                        {stat.percentage < 0 && (
                                          <TrendingDown
                                            fontSize="inherit"
                                            color="error"
                                          />
                                        )}
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                          </Fragment>
                        );
                      })}
                    </Box>
                    <Box
                      ml={{ xs: 0, lg: 2 }}
                      role="button"
                      width={40}
                      height={40}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius={50}
                      sx={{
                        cursor: "pointer",
                        transition: "all 150ms",
                        bgcolor: (theme) =>
                          alpha(theme.palette.primary.main, 0.15),
                        "&:hover": {
                          bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, 0.1),
                        },
                        "&:active": {
                          bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, 0.2),
                        },
                      }}
                      onClick={() =>
                        setCompareWith((prev) => {
                          const newCompareWith = [...prev];
                          newCompareWith[activeTab] = "";
                          return newCompareWith;
                        })
                      }
                    >
                      <Close color="primary" />
                    </Box>
                  </Box>
                )}
              </Box>

              {/* Chart */}
              <Box mt={3} width={1} height={350} overflow="hidden">
                <Line
                  key={JSON.stringify({
                    activeTab,
                    compareWith,
                    showPreviousPeriod,
                  })}
                  style={{ height: "100%", width: "100%" }}
                  // @ts-ignore
                  data={(canvas) => {
                    return {
                      labels: chartData?.map((el: any) => el.x),
                      datasets: [
                        {
                          fill: !compareWith[activeTab],
                          label: showPreviousPeriod
                            ? // ? isWeek
                              //   ? "This week"
                              //   : isMonth
                              //     ? "This month"
                              //     : "This period"
                              "This period"
                            : activeTab === 0
                              ? "Bookings"
                              : activeTab === 1
                                ? "Energy Consumption"
                                : "Revenue",
                          data: chartData?.map((el: any) => el.y),
                          borderColor: currentColor,
                          backgroundColor: currentColor + "0D",
                          tension: 0,
                          borderWidth: 3,
                          pointBackgroundColor: currentColor,
                          pointBorderColor: "white",
                          pointHoverBorderColor: "white",
                          pointRadius: 0,
                          pointBorderWidth: 0,
                          pointHoverRadius: 8,
                          pointHoverBorderWidth: 2,
                          borderCapStyle: "round",
                          borderJoinStyle: "round",
                        },
                        ...(showPreviousPeriod
                          ? [
                              {
                                fill: false,
                                label:
                                  "Previous " +
                                  (isWeek
                                    ? "7 days"
                                    : isMonth
                                      ? "30 days"
                                      : "period"),
                                data: previousChartData?.map((el: any) => el.y),
                                borderColor: colors[3],
                                backgroundColor: "transparent",
                                tension: 0,
                                borderWidth: 1.5,
                                borderDash: [8, 4],
                                pointBackgroundColor: colors[3],
                                pointBorderColor: "white",
                                pointHoverBorderColor: "white",
                                pointRadius: 0,
                                pointBorderWidth: 0,
                                pointHoverRadius: 8,
                                pointHoverBorderWidth: 2,
                                borderCapStyle: "round",
                                borderJoinStyle: "round",
                              },
                            ]
                          : []),
                        ...(!!compareWith[activeTab]
                          ? [
                              {
                                fill: false,
                                label:
                                  compareWithIndex === 0
                                    ? "Bookings"
                                    : compareWithIndex === 1
                                      ? "Energy Consumption (kWh)"
                                      : "Revenue (₹)",
                                data: compareWithChartData?.map(
                                  (el: any) => el.y,
                                ),
                                borderColor: compareWithColor,
                                backgroundColor: compareWithColor + "0D",
                                tension: 0,
                                borderWidth: 3,
                                pointBackgroundColor: compareWithColor,
                                pointBorderColor: "white",
                                pointHoverBorderColor: "white",
                                pointRadius: 0,
                                pointBorderWidth: 0,
                                pointHoverRadius: 8,
                                pointHoverBorderWidth: 2,
                                borderCapStyle: "round",
                                borderJoinStyle: "round",
                                yAxisID: "y1",
                              },
                            ]
                          : []),
                      ],
                    };
                  }}
                  options={{
                    scales: {
                      xAxis: {
                        // type: 'time',
                        title: {
                          display: true,
                          text:
                            granularity === "month"
                              ? "Month"
                              : granularity === "week"
                                ? "Start of Week"
                                : "Day",
                          padding: {
                            top: 8,
                          },
                        },
                        grid: {
                          display: false,
                          tickWidth: 0,
                          tickLength: 16,
                          drawBorder: false,
                        },
                        ticks: {
                          color: theme.palette.text.secondary,
                        },
                      },
                      yAxis: {
                        title: {
                          display: true,
                          text:
                            activeTab === 0
                              ? "Bookings"
                              : activeTab === 1
                                ? "Energy Consumption (kWh)"
                                : "Revenue (₹)",
                          padding: {
                            top: 0,
                            bottom: 8,
                          },
                        },
                        beginAtZero: true,
                        ticks: {
                          color: theme.palette.text.secondary,
                          precision: activeTab === 1 ? 3 : 0,
                        },
                        // suggestedMax: 10,
                        grid: {
                          borderDash: [4],
                          tickWidth: 0,
                          tickLength: 16,
                          drawBorder: false,
                          color: isDarkMode
                            ? "rgba(75, 75, 75, 0.6)"
                            : undefined,
                        },
                      },
                      y1: {
                        display: !!compareWith[activeTab],
                        position: "right",
                        title: {
                          display: true,
                          text:
                            compareWithIndex === 0
                              ? "Bookings"
                              : compareWithIndex === 1
                                ? "Energy Consumption (kWh)"
                                : "Revenue (₹)",
                          padding: {
                            top: 0,
                            bottom: 8,
                          },
                        },
                        beginAtZero: true,
                        ticks: {
                          color: theme.palette.text.secondary,
                          precision: 0,
                        },
                        grid: {
                          display: false,
                          drawBorder: false,
                        },
                        // suggestedMax: 10,
                      },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        caretSize: 0,
                        mode: "index",
                        intersect: false,
                        yAlign: "center",
                        displayColors:
                          showPreviousPeriod || !!compareWith[activeTab],
                        caretPadding: 16,
                        titleFont: {
                          size: 14,
                          weight: "400",
                        },
                        bodyFont: {
                          size: 14,
                          weight: "500",
                        },
                        cornerRadius: 6,
                        padding: 12,
                        usePointStyle: true,
                        titleColor: theme.palette.text.primary,
                        bodyColor: theme.palette.text.primary,
                        backgroundColor: theme.palette.background.paper,
                        borderWidth: 1,
                        borderColor: isDarkMode
                          ? theme.customColors.border
                          : "#E5E5E5",
                        callbacks: {
                          title: (tooltipItems) => {
                            const label = tooltipItems[0].label.replaceAll(
                              ",",
                              " ",
                            );
                            if (showPreviousPeriod)
                              return activeTab === 0
                                ? "Bookings"
                                : activeTab === 1
                                  ? "Energy Consumption"
                                  : "Revenue";
                            // +
                            // " | " +
                            // label
                            else return label;
                          },
                          label: (tooltipItem) => {
                            // prettier-ignore
                            const currentDate = tooltipItem.label.replaceAll(",", " ");
                            // prettier-ignore
                            const previousDate = previousChartData[tooltipItem.dataIndex].x.join(" ");

                            if (showPreviousPeriod) {
                              if (tooltipItem.datasetIndex === 0)
                                return `${currentDate}: ${tooltipItem.formattedValue}`;
                              else
                                return `${previousDate}: ${tooltipItem.formattedValue}`;
                            } else
                              return (
                                tooltipItem.dataset.label +
                                ": " +
                                tooltipItem.formattedValue
                              );
                          },
                        },
                      },
                    },
                    interaction: {
                      mode: "index",
                      intersect: false,
                    },
                  }}
                />
              </Box>

              {/* Legend */}
              <Box
                mb={-1}
                mt={1.5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  width={21}
                  height={4}
                  bgcolor={colors[activeTab]}
                  borderRadius={10}
                />
                <Box
                  ml={1}
                  fontSize={14}
                  fontWeight={600}
                  color={(theme) => theme.customColors.text.grey}
                >
                  {activeTab === 0
                    ? "Bookings"
                    : activeTab === 1
                      ? "Energy consumption"
                      : "Revenue"}
                  {/* {!compareWith[activeTab] && (isWeek || isMonth)
                    ? " " + rangeInWords
                    : ""} */}
                </Box>
                {showPreviousPeriod && (
                  <>
                    <Box
                      ml={4}
                      width={21}
                      borderBottom={`2px dashed ${colors[3]}`}
                    />
                    <Box
                      ml={1}
                      fontSize={14}
                      fontWeight={600}
                      color={(theme) => theme.customColors.text.grey}
                    >
                      {activeTab === 0
                        ? "Bookings"
                        : activeTab === 1
                          ? "Energy consumption"
                          : "Revenue"}
                      {isWeek
                        ? " in previous 7 days"
                        : isMonth
                          ? " in previous 30 days"
                          : " in previous period"}
                    </Box>
                  </>
                )}
                {compareWith[activeTab] && (
                  <>
                    <Box
                      ml={4}
                      width={21}
                      height={4}
                      bgcolor={
                        compareWith[activeTab] === "bookings"
                          ? colors[0]
                          : compareWith[activeTab] === "consumption"
                            ? colors[1]
                            : colors[2]
                      }
                      borderRadius={10}
                    />
                    <Box
                      ml={1}
                      fontSize={14}
                      fontWeight={600}
                      color={(theme) => theme.customColors.text.grey}
                    >
                      {compareWith[activeTab] === "bookings"
                        ? "Bookings"
                        : compareWith[activeTab] === "consumption"
                          ? "Energy consumption"
                          : "Revenue"}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        mt={4}
        mb={6}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <Box display="flex" alignItems="center">
          <Box
            fontSize={{ xs: 14, lg: 16 }}
            fontWeight={400}
            color={(theme) => theme.customColors.text.plain}
          >
            Want to download the full report?
          </Box>
          <Button
            sx={{
              ml: 2,
              py: 1,
              px: 4,
              borderRadius: 50,
              textTransform: "none",
            }}
            variant="outlined"
            startIcon={<PendingActionsOutlined />}
            component={NavLink}
            to="/reports"
          >
            Reports
          </Button>
        </Box>
        <Box
          mt={{ xs: 2, sm: 0 }}
          mb={{ xs: -2, sm: 0 }}
          display="flex"
          alignItems="center"
          component={NavLink}
          to="/bookings"
          sx={{ textDecoration: "none" }}
        >
          <Box
            fontSize={{ xs: 14, lg: 16 }}
            fontWeight={400}
            color={(theme) => theme.customColors.text.plain}
          >
            Go to bookings
          </Box>
          <Button
            variant="contained"
            sx={{
              ml: 2,
              borderRadius: 50,
              minWidth: 0,
              width: { xs: 36, sm: 42 },
              height: { xs: 36, sm: 42 },
              fontSize: 16,
            }}
          >
            <ArrowForwardIosRounded fontSize="inherit" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const AddComparisonButton = ({ activeTab, setCompareWith }: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <Button
        className="rounded"
        size="small"
        variant="outlined"
        endIcon={<Add />}
        sx={{
          height: 32,
          px: 2,
          textTransform: "none",
          fontSize: 14,
          lineHeight: "1em",
          fontWeight: 700,
          border: (theme) => `1px dashed ${theme.palette.primary.main}`,
          "&:hover": {
            border: (theme) => `1px dashed ${theme.palette.primary.light}`,
          },
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        Add Comparison
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {[
          {
            label: "Bookings",
            value: "bookings",
          },
          {
            label: "Energy Consumption",
            value: "consumption",
          },
          {
            label: "Revenue",
            value: "revenue",
          },
        ].map(
          (el, i) =>
            activeTab !== i && (
              <MenuItem
                key={i}
                onClick={() => {
                  setCompareWith((prev: any) => {
                    const newCompareWith = [...prev];
                    newCompareWith[activeTab] = el.value;
                    return newCompareWith;
                  });
                  setAnchorEl(null);
                }}
              >
                {el.label}
              </MenuItem>
            ),
        )}
      </Menu>
    </>
  );
};

export const Trend = ({ percentage }: { percentage: number | null }) => {
  if (percentage === null) return null;
  return (
    <Box
      sx={{
        mb: 0.5,
        fontSize: { xs: 12, lg: 14 },
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box sx={{ mr: 0.75 }}>
        {percentage > 0 ? "+" : ""}
        {percentage.toLocaleString(undefined, {
          maximumFractionDigits: percentage < 100 ? 1 : 0,
        })}
        %
      </Box>
      {percentage > 0 && <TrendingUp fontSize="inherit" color="success" />}
      {percentage < 0 && <TrendingDown fontSize="inherit" color="error" />}
    </Box>
  );
};

export default Highlights;
