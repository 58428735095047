import { EditOutlined } from "@mui/icons-material";
import { Box, IconButton, Tooltip, useTheme } from "@mui/material";
import React from "react";

const InfoTable = ({ data, ...props }: any) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  return (
    <Box
      overflow="hidden"
      width={1}
      display="grid"
      gridTemplateColumns="auto auto"
      {...props}
      sx={{
        "& .cell": {
          py: 1.5,
          px: 2.5,
          fontSize: "14px",
          lineHeight: "1.35em",
          borderColor: (theme) => theme.customColors.border,
        },
        "& .label": {
          color: (theme) => theme.customColors.text.plain,
          borderLeft: (theme) => `1px solid ${theme.customColors.border}`,
        },
        "& .value": {
          borderRight: (theme) => `1px solid ${theme.customColors.border}`,
        },
        "& .first": {
          pt: 3,
        },
        "& .last": {
          pb: 3,
          borderBottom: (theme) => `1px solid ${theme.customColors.border}`,
          "&.label": {
            borderBottomLeftRadius: "4px",
          },
          "&.value": {
            borderBottomRightRadius: "4px",
          },
        },
        "& .custom-render": {
          p: 0,
        },
        ...props.sx,
      }}
    >
      {data?.map((section: any, i: number) => (
        <React.Fragment key={i}>
          <Box
            mt={i === 0 ? 0 : 2}
            px={2.5}
            py={1.5}
            gridColumn="1/-1"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            color={isDarkMode ? "#fff" : "#232D42"}
            bgcolor={(theme) => theme.customColors.header}
            border={(theme) => `1px solid ${theme.customColors.border}`}
            borderBottom={0}
            borderRadius="4px 4px 0 0"
          >
            <Box
              fontSize="12px"
              fontWeight={700}
              lineHeight="1.35em"
              textTransform="uppercase"
            >
              {section.heading || "Info"}
            </Box>
            {section.action && (
              <Tooltip title={section.action.tooltip || "Edit"}>
                <IconButton
                  size="small"
                  sx={{ my: -2 }}
                  color="primary"
                  onClick={section.action.onClick || (() => {})}
                >
                  <EditOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {section.rows?.map((row: any, j: number, arr: any) => {
            const firstAndLastClassNames = `${j === 0 ? " first" : ""}${j === arr.length - 1 ? " last" : ""}`;

            return (
              <React.Fragment key={j}>
                {row.render ? (
                  <Box
                    gridColumn="1/-1"
                    className={`cell label value custom-render${firstAndLastClassNames}`}
                    sx={{ overflowX: "auto" }}
                  >
                    {row.render}
                  </Box>
                ) : (
                  <>
                    <Box className={`cell label${firstAndLastClassNames}`}>
                      {row.label}
                    </Box>
                    <Box className={`cell value${firstAndLastClassNames}`}>
                      {row.value}
                    </Box>
                  </>
                )}
              </React.Fragment>
            );
          })}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default InfoTable;
