import { Circle, InfoOutlined } from "@mui/icons-material";
import { Box, Tooltip, useTheme } from "@mui/material";
import CircularLoader from "components/CircularLoader";
import moment from "moment";
import { Line } from "react-chartjs-2";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { GlobalState, authorizedFetch } from "utils";
import { BOLT_EARTH_COMPANY_ID, BOLT_URL } from "utils/constants";

const ChargerLogs = ({ bookingId, isOCPP }: any) => {
  // bookingId = "63c7d2b946bd980012bd63d5"; // level 2 test ID
  // bookingId = "640ace5f58a7cccb696a5cea"; // level 3 test ID

  const { company } = useSelector((state: GlobalState) => state.global);

  const { isLoading, data } = useQuery(
    ["chargerLogs", bookingId, isOCPP],
    () =>
      authorizedFetch(
        isOCPP
          ? `${BOLT_URL}/company/charger/ocpp/metervalues?bookingId=${bookingId}`
          : `${BOLT_URL}/charger/${bookingId}/logs/typef`
      ),
    {
      enabled: !!bookingId,
    }
  );

  const { isLoading: isStatusLoading, data: statusData } = useQuery(
    ["getLogStatus", bookingId],
    () =>
      authorizedFetch(
        `${BOLT_URL}/company/booking/logstatus/levelone?bookingId=${bookingId}`
      ),
    {
      enabled: !!bookingId,
    }
  );

  const isSynced = isOCPP ? true : statusData?.data?.logStatus?.F;

  return (
    <Box
      sx={{
        display: "grid",
        gap: 2,
        px: 0.5,
      }}
    >
      {company.id === BOLT_EARTH_COMPANY_ID && !isOCPP ? (
        <Box
          typography="body2"
          sx={{
            borderCollapse: "collapse",
            "& td, th": {
              p: 1,
              textAlign: "left",
              border: (theme) => `1px solid ${theme.palette.divider}`,
            },
          }}
          component="table"
        >
          <tr>
            <th>Packet Type</th>
            <th>Status</th>
          </tr>
          {[
            {
              key: "F",
              label: "F/C",
              description:
                "First/Continue Packet: Captures the live energy consumption, voltage, current and temperature for the charging session.",
            },
            {
              key: "I",
              label: "I",
              description:
                "Summery Packet: Captures the final duration and energy consumption for the charging session.",
            },
            {
              key: "E",
              label: "E",
              description:
                "Event Packet: Helps you identify any command execution or disruption during an ongoing session.",
            },
            // {
            //   key: "P",
            //   label: "P",
            //   description:
            //     "Power Packet: Captures the variations in the power supply of the Charge Point.",
            // },
          ].map((el, i) => (
            <tr key={i}>
              <td>
                <Box display="flex" alignItems="center">
                  {el.label}
                  <Tooltip title={el.description} placement="right">
                    <InfoOutlined
                      sx={{ ml: 1, fontSize: 14, cursor: "pointer" }}
                      color="disabled"
                    />
                  </Tooltip>
                </Box>
              </td>
              <td>
                {isStatusLoading ? (
                  "Loading..."
                ) : (
                  <Circle
                    sx={{ fontSize: 14 }}
                    color={
                      statusData?.data?.logStatus?.[el.key]
                        ? "success"
                        : "error"
                    }
                  />
                )}
              </td>
            </tr>
          ))}
        </Box>
      ) : null}
      {isLoading || isStatusLoading ? (
        <CircularLoader />
      ) : isSynced ? (
        (isOCPP
          ? [
              {
                objKey: "energy",
                label: "Energy Consumed",
                unit: "kWh",
              },
              ...(data?.data?.modelId === "OCPP_LEVEL_3"
                ? [
                    {
                      objKey: "SoC",
                      label: "State of Charge",
                      unit: "%",
                    },
                  ]
                : []),
            ]
          : [
              {
                objKey: "current",
                label: "Current",
                unit: "A",
              },
              {
                objKey: "temperature",
                label: "Temperature",
                unit: "°C",
              },
              {
                objKey: "voltage",
                label: "Voltage",
                unit: "V",
              },
              {
                objKey: "activePower",
                label: "Active Power",
                unit: "kW",
              },
              {
                objKey: "energy",
                label: "Energy Consumed",
                unit: "kWh",
              },
            ]
        ).map((el, i) => (
          <LineChart
            key={i}
            {...{
              isLoading,
              data,
              isOCPP,
              objKey: el.objKey,
              label: el.label,
              unit: el.unit,
            }}
          />
        ))
      ) : null}
    </Box>
  );
};

function formatValue(value: any, divisor: number) {
  return parseFloat((parseFloat(value) / divisor).toFixed(5));
}

const LineChart = ({ data, isOCPP, objKey, label, unit }: any) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const getChartData = () => {
    if (isOCPP) {
      const logs =
        data?.data?.logs?.constructor === Array ? data?.data?.logs : [];

      const hasInterval = logs.some((log: any) =>
        log.some((entry: any) =>
          entry.sampledValue.some(
            (sample: any) =>
              sample.measurand === "Energy.Active.Import.Interval"
          )
        )
      );

      return logs
        .reduce((result: any, log: any) => {
          log.forEach((entry: any) => {
            const { timestamp, sampledValue } = entry;

            if (objKey === "energy") {
              // Use interval value if available, otherwise use register value
              let key = hasInterval
                ? "Energy.Active.Import.Interval"
                : "Energy.Active.Import.Register";

              const value = sampledValue.find(
                (sample: any) => sample.measurand === key
              )?.value;

              if (
                value !== undefined &&
                // Prevent duplicate entries
                !result.find(
                  (el: any) =>
                    el.x.toLocaleString() ===
                    new Date(timestamp).toLocaleString()
                )
              ) {
                result.push({
                  x: new Date(timestamp),
                  y: formatValue(value, 1000),
                });
                return;
              }
            } else {
              const sample = sampledValue.find(
                (sample: any) =>
                  sample.measurand === objKey &&
                  sample.context === "Sample.Periodic"
              );
              if (sample !== undefined)
                result.push({
                  x: new Date(timestamp),
                  y: formatValue(sample.value, 1),
                });
            }
          });

          return result;
        }, [])
        .sort(
          (a: any, b: any) => moment(a.x).valueOf() - moment(b.x).valueOf()
        );
    } else {
      return (data?.data?.data?.constructor === Array ? data.data.data : [])
        .map((el: any) => ({
          x: new Date(el.timestamp),
          y: formatValue(
            el[objKey] || 0,
            ["activePower", "energy"].includes(objKey) ? 1000 : 1
          ),
        }))
        .sort(
          (a: any, b: any) => moment(a.x).valueOf() - moment(b.x).valueOf()
        );
    }
  };

  const chartData = getChartData();

  let color = "rgba(97,209,105)";

  return (
    <Box
      sx={{
        width: 1,
        height: 200,
      }}
    >
      <Line
        data={(canvas) => {
          const ctx = canvas.getContext("2d");
          const g = ctx?.createLinearGradient(0, 0, 0, 60);

          g?.addColorStop(0, "rgba(97, 209, 105, 0.6)");
          g?.addColorStop(0.5, "rgba(97, 209, 105, 0.2)");
          g?.addColorStop(
            1,
            isDarkMode ? "rgba(0, 0, 0, 0)" : "rgba(255, 255, 255, 0)"
          );

          return {
            datasets: [
              {
                label: label,
                data: chartData,
                fill: true,
                tension: 0,
                borderWidth: 2,
                borderColor: color,
                backgroundColor: g,

                pointRadius: 0,
                pointBorderColor: color,
                pointBorderWidth: 2,
                pointBackgroundColor: color,

                pointHoverRadius: 4,
                pointHoverBorderColor: color,
                pointHoverBorderWidth: 3,
                pointHoverBackgroundColor: "#fff",
              },
            ],
          };
        }}
        options={{
          animation: {
            duration: 0,
          },
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              caretSize: 0,
              mode: "index",
              intersect: false,
              yAlign: "center",
              usePointStyle: true,
              // displayColors: false,
              caretPadding: 16,
              titleFont: {
                weight: "400",
              },
              bodyFont: {
                weight: "500",
              },
              callbacks: {
                label: (context: any) => {
                  return `${label}: ${context.parsed.y}${
                    ["%", "°C"].includes(unit) ? "" : " "
                  }${unit}`;
                },
              },
            },
          },
          interaction: {
            mode: "index",
            intersect: false,
          },
          scales: {
            xAxis: {
              type: "time",
              offset: true,
              grid: {
                display: false,
                drawBorder: false,
                tickLength: 0,
              },
              ticks: {
                major: {
                  enabled: true,
                },
                color: theme.palette.text.secondary,
              },
            },
            yAxis: {
              offset: true,
              title: {
                display: true,
                text: `${label} (${unit})`,
                padding: {
                  top: 0,
                  bottom: 15,
                },
                color: theme.customColors.grey,
                font: {
                  weight: "500",
                  size: 12,
                },
              },
              ticks: {
                color: theme.palette.text.secondary,
              },
              min: 0,
              grid: {
                borderDash: [10],
                tickWidth: 0,
                tickLength: 16,
                drawBorder: false,
              },
            },
          },
        }}
      />
    </Box>
  );
};

export default ChargerLogs;
