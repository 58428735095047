import {
  Box,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Avatar,
  Tooltip,
} from "@mui/material";

import Table from "components/Table";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";
import moment from "moment";
import { HEALTH_URL, OCPP_URL, USERTRACK_URL } from "utils/constants";
import { InfoOutlined } from "@mui/icons-material";

interface Props {
  data: any;
  tab: number;
  open: boolean;
}

const ChargerHistory: React.FC<Props> = ({ data }) => {
  const [note, setNote] = useState("");

  const logsUrl = `${USERTRACK_URL}/getlogs?size=100&productId=${data?.charger?.chargerId}&service=${HEALTH_URL}`;
  const {
    isLoading,
    data: logsData,
    refetch,
  } = useQuery(["getLogs", data?.charger?.chargerId], () =>
    authorizedFetch(logsUrl)
  );

  // let timer: any;
  // const pingTimer = (data: any) => {
  //   timer = setTimeout(() => {
  //     setPingArr((oldArray: any) => [
  //       {
  //         time: data.message,
  //         id: `${pingArr.length + 1}`,
  //         status: data.status,
  //       },
  //       ...oldArray,
  //     ]);
  //   }, 10000);
  // };

  const mutationPing = useMutation(
    "addPing",
    () =>
      authorizedFetch(`${OCPP_URL}/OCPP/1.6/chargepoint/input`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          input: "heart",
        },
      }),
    {
      onSuccess: (data) => {
        // snackbar.success(`Ping Fetched`);

        setPingArr((oldArray: any) => [
          {
            time: data.message,
            id: `${pingArr.length + 1}`,
            status: data.status,
          },
          ...oldArray,
        ]);

        // pingTimer(data);
      },
    }
  );

  // const mutationPing = useMutation(
  //   "addPing",
  //   () =>
  //     authorizedFetch(`https://ocpp.revos.in/OCPP/1.6/chargepoint/input`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: {
  //         input: "heart",
  //       },
  //     }),
  //   {
  //     onSuccess: (data) => {
  //       // snackbar.success(`Ping Fetched`);

  //       setPingArr((oldArray: any) => [
  //         {
  //           time: data.message,
  //           id: `${pingArr.length + 1}`,
  //           status: data.status,
  //         },
  //         ...oldArray,
  //       ]);

  //       // pingTimer(data);
  //     },
  //   }
  // );

  const mutation = useMutation(
    "addNote",
    () =>
      authorizedFetch(`${HEALTH_URL}/product/note`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: "1234",
        },
        body: {
          productId: data?.charger?.chargerId,
          productType: "CHARGING_SOCKET",
          note: note,
        },
      }),
    {
      onSuccess: () => {
        snackbar.success(`Note Added`);
        refetch();
        setLoader(false);
      },
      onError: () => {
        snackbar.error(`Error adding note`);
      },
    }
  );

  const [pingArr, setPingArr] = useState<any>([]);
  const [logsFilter, setLogsFilter] = useState("health");

  useEffect(() => {
    if (pingArr.length === 0) {
      mutationPing.mutate();
    } else {
      setTimeout(() => {
        mutationPing.mutate();
      }, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pingArr]);

  // useEffect(() => {
  //   if (pingArr.length === 0) {
  //     mutationPing.mutate();
  //   } else {
  //     setTimeout(() => {
  //       mutationPing.mutate();
  //     }, 10000);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pingArr]);

  function onAdd() {
    setLoader(true);
    mutation.mutate();
    refetch();
  }

  return (
    <Box>
      {data?.chargerType?.communicationProtocol === "OCPP" ? (
        <Box px={2.5} my={1} display="flex" alignItems="center">
          <RadioGroup
            row
            value={logsFilter}
            onChange={(e: any) => setLogsFilter(e.target.value)}
          >
            <FormControlLabel
              value="health"
              control={<Radio />}
              label="HEALTH"
            />
            <FormControlLabel value="ping" control={<Radio />} label="PING" />
          </RadioGroup>
          <Tooltip title="Charger is Pinged every 10 seconds">
            <InfoOutlined
              fontSize="inherit"
              sx={{ ml: -1.5, mt: -0.1, cursor: "pointer", color: "#00000080" }}
            />
          </Tooltip>
        </Box>
      ) : (
        ""
      )}

      {logsFilter === "health" ||
      data?.chargerType?.communicationProtocol !== "OCPP" ? (
        <>
          <Table
            px={0}
            // height={
            //   data?.charger?.health === "HEALTHY" &&
            //     data?.chargerType?.communicationProtocol !== "OCPP"
            //     ? 390
            //     : data?.charger?.health === "HEALTHY" &&
            //       data?.chargerType?.communicationProtocol === "OCPP"
            //       ? 320
            //       : 270
            // }
            // rowsPerPage={data?.charger?.health === "HEALTHY" ? 6 : 4}
            rows={logsData?.data || []}
            loading={isLoading}
            columns={[
              {
                key: "updatedData.create.activity.activityType",
                label: "Type",
              },
              {
                key: "updatedData.create.activity.health",
                label: "Health",
              },
              {
                key: "updatedData.create.activity.userName",
                label: "By",
              },
              {
                key: "timestamp",
                label: "Date",
                format: (value) => moment(value).format("MMM DD, YYYY, HH:mm"),
              },
              {
                key: "updatedData.create.activity.note",
                label: "Description",
              },
            ]}
          />
          <FormControl
            fullWidth
            sx={{
              px: 2,
              "& .MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-input": {
                  fontSize: 13,
                },
              },
            }}
            variant="outlined"
          >
            <OutlinedInput
              fullWidth
              id="outlined-multiline-static"
              multiline
              rows={2}
              placeholder="Any update related to the Charger? Add a custom note..."
              value={note}
              onChange={(e: any) => {
                setNote(e.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    disabled={note.length === 0}
                    variant="contained"
                    size="small"
                    sx={{ height: 45, width: 90, fontSize: 14 }}
                    onClick={onAdd}
                  >
                    Add
                  </Button>
                </InputAdornment>
              }
            />
          </FormControl>
        </>
      ) : (
        <>
          <Table
            height={430}
            rowsPerPage={5}
            rows={pingArr || []}
            loading={false}
            columns={[
              {
                key: "time",
                label: "Pinged At",
                format: (value) =>
                  moment(value).format("MMM DD, YYYY HH:mm:ss"),
              },
              {
                key: "status",
                label: "Status",
                Render: (row) => {
                  return (
                    <Avatar variant="status">
                      {row.status === 200 ? "Successful" : "Failed"}
                    </Avatar>
                  );
                },
              },
            ]}
          />
        </>
      )}
    </Box>
  );
};

export default ChargerHistory;
